.dialog-title-container {
    display: flex;
}

.current-attachment-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    color: cornflowerblue;
}

.attachment-error {
    font-size: medium;
    color: red;
    font-weight: 500;
    margin-bottom: 10px;
}

.comment-attachment-container {
    justify-content: space-between;
    margin-left: auto;
    text-align: right;
    padding: 10px 20px;
}

.comment-attachment {
    align-self: center;
    cursor: pointer;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.2rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    background-color: #f3f2f1;
}

    .comment-attachment label:hover {
        color: #c8c6c4;
        background-color: #f3f2f1;
        border-color: #f3f2f1;
    }

    .comment-attachment label:focus {
        outline: 0;
    }

.comment-attachment-list {
    display: flex;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid;
    padding: 0.5rem 0.1rem;
    margin-bottom: 0;
    font-size: 0.1rem;
    /* line-height: 1.5; */
    border-color: #c8c6c4;
}

    .comment-attachment-list img {
        background-color: white;
        padding: 0 8px 0 3px;
        height: 30px;
    }

.attachment-delete {
    /* color: red; */
    display: flex;
    margin-left: auto;
    padding: 4px 1.5px;
    cursor: pointer;
}

.comment-attachment-list h6 {
    padding: 1px 0;
    margin-top: 5px;
    /* max-width: 165px; */
    overflow: hidden;
}

.custom-file-upload {
    border: 1px solid #f3f2f1;
    display: inline-block;
    cursor: pointer;
}
