@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
/* fontawesome */
/* [class*='fa-']:before {
  font-family: 'FontAwesome', sans-serif;
} */

.progress_body {
    background: #fff;
    color: #333;
    height: 20vh;
    width: 100%;
    /* font: 14px/140% Helvetica, Arial, sans-serif; */
}

.progress-tracker {
    counter-reset: steps;
    display: inline-block;
    list-style: none;
    margin-left: 4em;
    padding: 0 0 2em 0;
    text-align: left;
}

    .progress-tracker li {
        border-bottom: solid 4px #989898;
        counter-increment: steps;
        display: inline-block;
        position: relative;
        margin-right: -0.375em;
    }

        .progress-tracker li:last-child {
            border-bottom: none;
        }

        .progress-tracker li:after {
            background: #989898;
            border: solid 4px transparent;
            border-radius: 50%;
            color: #fff;
            content: counter(steps);
            font-weight: bold;
            display: block;
            position: absolute;
            text-align: center;
            width: 2em;
            bottom: -1.1em;
            height: 2em;
            line-height: 2em;
            left: -4px;
            bottom: -1.13em;
        }

        .progress-tracker li span {
            display: inline-block;
            color: #7f7f7f;
            position: relative;
            text-align: center;
            width: 8em;
            left: -3em;
            top: -1.15em;
            font-weight: bold;
            overflow: hidden;
        }

        .progress-tracker li.completed {
            border-bottom-color: #2a9d8f;
        }

            .progress-tracker li.completed:after {
                background: #2a9d8f;
                border: 1em;
            }

            .progress-tracker li.completed span {
                color: #2a9d8f;
            }

        .progress-tracker li.current:after {
            background: #fff;
            border-color: #ffc600;
        }

        .progress-tracker li.current span {
            color: #959a9c;
            font-weight: 750;
        }

        .progress-tracker li.skipped {
            border-bottom-color: #959a9c;
        }

            .progress-tracker li.skipped:after {
                background: #e5383b;
                color: #fff;
                border: 2em;
            }

            .progress-tracker li.skipped span {
                color: #c02e3e;
            }

        .progress-tracker li.dotted {
            border-bottom-style: dotted;
            border-color: #2a9d8f;
        }

    .progress-tracker.v2 {
        padding-left: 3.25em;
    }

        .progress-tracker.v2 li {
            width: 6.5em;
        }

            .progress-tracker.v2 li span {
                width: 6.5em;
                left: -2.25em;
            }

    .progress-tracker.icon li:after {
        content: '';
        font-family: 'FontAwesome';
    }

    .progress-tracker.icon li.completed:after {
        content: '\f00c';
    }

    .progress-tracker.icon li.completed.document:after {
        content: '\f0f6';
    }

    .progress-tracker.icon li.skipped:after {
        content: '\f00d';
    }

    .progress-tracker.icon li.skipped.expire:after {
        content: '\f017';
    }

@media (min-width: 576px) {
    .progress-tracker li {
        width: 4em;
    }

        .progress-tracker li span {
            font-size: 0.7em;
        }
}

@media (min-width: 768px) {
    .progress-tracker li {
        width: 8em;
    }

        .progress-tracker li span {
            font-size: 0.75em;
        }
}

@media (min-width: 992px) {
    .progress-tracker li {
        width: 8em;
    }

        .progress-tracker li span {
            font-size: 0.75em;
        }
}

@media (min-width: 1200px) {
    .progress-tracker li {
        width: 8em;
    }

        .progress-tracker li span {
            font-size: 0.75em;
        }
}

/* Spinner CSS */

.news-spinner {
    position: relative;
    top: 60%;
    margin-top: 50px;
    align-content: center;
    text-align: center;
    left: 50%;
    z-index: 1;
    height: 40px;
    width: 40px;
    transform: translate(-50%, -50%);
}

[class^='ball-'] {
    position: absolute;
    display: block;
    left: 30px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: all 0.5s;
    animation: circleRotate 4s both infinite;
    transform-origin: 0 250% 0;
}

@keyframes circleRotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(1440deg);
    }
}

.ball-1 {
    z-index: -1;
    background-color: #ffe169;
    animation-timing-function: cubic-bezier(0.5, 0.3, 0.9, 0.9);
}

.ball-2 {
    z-index: -2;
    background-color: #fad643;
    animation-timing-function: cubic-bezier(0.5, 0.6, 0.9, 0.9);
}

.ball-3 {
    z-index: -3;
    background-color: #edc531;
    animation-timing-function: cubic-bezier(0.5, 0.9, 0.9, 0.9);
}

.ball-4 {
    z-index: -4;
    background-color: #dbb42c;
    animation-timing-function: cubic-bezier(0.5, 1.2, 0.9, 0.9);
}

.ball-5 {
    z-index: -5;
    background-color: #dcae1d;
    animation-timing-function: cubic-bezier(0.5, 1.5, 0.9, 0.9);
}

.ball-6 {
    z-index: -6;
    background-color: #dcae1d;
    animation-timing-function: cubic-bezier(0.5, 1.8, 0.9, 0.9);
}

.ball-7 {
    z-index: -7;
    background-color: #dcae1d;
    animation-timing-function: cubic-bezier(0.5, 2.1, 0.9, 0.9);
}

.ball-8 {
    z-index: -8;
    background-color: #dcae1d;
    animation-timing-function: cubic-bezier(0.5, 2.4, 0.9, 0.9);
}
