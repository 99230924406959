.grid-container-user {
    width: 100%;
}

.form-heading-user-manage {
    color: #333333;
    font-weight: bold;
    font-size: 22px;
    margin-left: -14px;
}

.form-title-user-manage {
    color: #333333;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    border-bottom: 3px solid #ffd100;
}

.register-card-body {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.19), 0 5px 5px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
}

.card-form {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
}

@media (max-width: 576px) {
    .grid-container-user {
        max-width: 80vw;
    }
}

@media (min-width: 1200px) {
    .grid-container-user {
        max-width: 76vw;
    }
}
b {
    font-size: 15px;
}
#app-cover {
    display: table;
    width: 600px;
    margin: 80px auto;
    counter-reset: button-counter;
}

.row {
    display: table-row;
}

.toggle-button-cover {
    display: table-cell;
    position: relative;
    width: 200px;
    height: 140px;
    box-sizing: border-box;
}

.button-cover {
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
}

    .button-cover:before {
        counter-increment: button-counter;
        content: counter(button-counter);
        position: absolute;
        right: 0;
        bottom: 0;
        color: #d7e3e3;
        font-size: 12px;
        line-height: 1;
        padding: 5px;
    }

.button-cover,
.knobs,
.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

    .button.r,
    .button.r .layer {
        border-radius: 100px;
    }

    .button.b2 {
        border-radius: 2px;
    }

.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}

/* Button 4 */
#button-4 .knobs:before,
#button-4 .knobs:after {
    position: absolute;
    top: 4px;
    left: 4px;
    /* width: 20px;
  height: 10px; */
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 5px;
    background-color: #03a9f4;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-4 .knobs:before {
    content: "YES";
}

#button-4 .knobs:after {
    content: "NO";
}

#button-4 .knobs:after {
    top: -28px;
    right: 4px;
    left: 42px;
    background-color: #f44336;
}

#button-4 .checkbox:checked + .knobs:before {
    top: -28px;
}

#button-4 .checkbox:checked + .knobs:after {
    top: 4px;
}

#button-4 .checkbox:checked ~ .layer {
    background-color: #fcebeb;
}

#button-4 .checkbox:disabled + .knobs:after {
    color: white;
    /* background-color: rgb(231, 135, 135); */
    background-color: rgb(231, 152, 152);
}

#button-4 .checkbox:disabled + .knobs:before {
    color: white;
    background-color: #aadcf3;
}

.labelUser {
    overflow: hidden;
    white-space: nowrap;
}

.optionListContainer {
    z-index: 3 !important;
}