/*
* 
CARD COUNTER
 * 
 */

.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  border-left: 6px solid #f5bb00;
  background-color: 'white';
  color: #f5bb00;
}

.card-counter.danger {
  border-left: 6px solid #d9212d;
  background-color: 'white';
  color: #d9212d;
}

.card-counter.success {
  border-left: 6px solid #38a3a5;
  background-color: 'white';
  color: #38a3a5;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5rem;
  opacity: 0.2;
  left: 300px;
  bottom: 300px;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 5px;
  font-size: 50px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 70px;
  font-style: normal;
  font-weight: 600;
  font-family: 'Montserrat';
  text-transform: capitalize;
  opacity: 0.9;
  text-transform: uppercase;
  display: block;
  font-size: 18px;
}
