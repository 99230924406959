.container-grid {
  width: 100%;
}

@media (max-width: 576px) {
  .container-grid {
    max-width: 94vw;
  }
}

@media (min-width: 1200px) {
  .container-grid {
    max-width: 77vw;
  }
}
