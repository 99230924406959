.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 42px;
    width: 100%;
    overflow-y: scroll;
    height: auto;
    max-height: 200px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Montserrat, sans-serif;
    font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 20;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

/* Spinner */

.autosuggest-wrapper {
    height: 10px;
    left: -50px;
    display: flex;
    justify-content: center;
    width: auto;
}

.autosuggest-spinner {
    height: 54px;
    width: 30px;
    display: flex;
    margin-top: 8px;
    justify-content: center;
    align-items: left;
    margin-left: -20px;
    justify-content: space-around;
}

.autosuggest-spinner--bar {
    width: 4px;
    height: 40%;
    background: #dcae1d;
}

    .autosuggest-spinner--bar:nth-child(1) {
        -webkit-animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        -webkit-animation-delay: 50ms;
        animation-delay: 50ms;
    }

    .autosuggest-spinner--bar:nth-child(2) {
        -webkit-animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        -webkit-animation-delay: 100ms;
        animation-delay: 100ms;
    }

    .autosuggest-spinner--bar:nth-child(3) {
        -webkit-animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        -webkit-animation-delay: 150ms;
        animation-delay: 150ms;
    }

@-webkit-keyframes jump {
    0%, 100% {
        transform: scaleY(1);
    }

    35% {
        transform: scaleY(1.6);
    }

    70% {
        transform: scaleY(0.8);
    }
}

@keyframes jump {
    0%, 100% {
        transform: scaleY(1);
    }

    35% {
        transform: scaleY(1.6);
    }

    70% {
        transform: scaleY(0.8);
    }
}
