.report-container {
  top: 64px;
  padding-top: 1em;
}

@media (max-width: 599px) {
  .report-container {
    top: 56px;
  }
}
