.Area {
  margin: 0 auto;
  width: 100%;
  display: table;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.Receiver {
  display: grid;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 10px;
}

.Message {
  display: flex;
  /* background-color: #e9ecef; */
  padding: 15px;
}
.L {
  float: left;
}

.R {
  float: right;
}
.text {
  color: black;
  font-family: tahoma;
  font-size: 15px;
  font-weight: bolder;
  line-height: 30px;
  width: 100%;
  border: 1px solid #d3d3d3;
  /* margin-top: 10px; */
  border-radius: 5px;
  padding: 5px;
}
.Area textarea {
  font-size: 12px;
  width: 90%;
  max-width: 90%;
  min-width: 90%;
  padding: 5%;
  border-radius: 5px;
  border: 1px solid #f0f0f1;
  max-height: 50px;
  height: 50px;
  min-height: 50px;
  /* background-color: #333; */
  color: #fff;
  outline: none;
  border: 1px solid transparent;
  resize: none;
}
.Area textarea:focus {
  color: #333;
  border: 1px solid #ccc;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
  background-color: #fff;
}
.Area .note {
  color: #9f6905;
  font-size: 10px;
}
.Area input[type='button'] {
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f0f0f1;
  background-color: #333;
  color: #fff;
  outline: none;
  border: 1px solid transparent;
  float: left;
}
.Area input[type='button']:hover {
  color: #fff;
  border: 1px solid #ccc;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  transition: all 1.5s;
  background-color: #9f6905;
}
.validation {
  float: left;
  background-color: #ccc;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  line-height: 14px;
  height: 0px;
  margin-left: 5px;
  display: none;
}
