.date-picker {
    z-index: 1030;
    position: absolute;
    background-color: white;
}

.date-range {
    z-index: 1030;
    position: absolute;
    background-color: white;
}

.btn-range {
    z-index: 1030;
}

.date-picker-container {
    padding-right: 10px;
    padding-left: 0;
    padding-bottom: 10px;
}

@media (max-width: 468px) {
    .date-picker-container {
        padding-right: 0px;
        padding-left: 0;
        width: 100%;
        padding-bottom: 0px;
    }

    .date-picker {
        border-right: 0;
        font-size: 15px;
        max-width: auto;
    }

    .date-range {
        margin-left: 0;
        margin-top: 15px;
    }

    .btn-range {
        margin-left: 0;
        margin-top: 15px;
        width: 100%;
    }

    .btn-date-picker {
        margin-left: 0;
        width: 100%;
    }

    .small-heading {
        font-size: 10px;
    }
}
