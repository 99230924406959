.form-item-container {
  top: 64px;
  padding-top: 1em;
}

@media (max-width: 599px) {
  .form-item-container {
    top: 56px;
  }
}
