.detail-title {
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
    font-family: Montserrat;
}

.card-img-top-detail {
    background-color: #ffd100;
    height: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.detail-card {
    border-radius: 0;
    border: 2px solid rgba(233, 236, 239, 1);
    border-top: 0;
}

.card-sticky {
    top: 56px;
    max-height: 91px;
    z-index: 1030;
    padding-top: 1em;
}

    .card-sticky h5 {
        color: #333333;
        font-weight: bold;
        font-family: Montserrat;
    }
